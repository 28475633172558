import { IsDefined } from '@utils/js-ts';
const isUserPortfolioOwner = (portfolio, user) => {
    if (!IsDefined(portfolio) || !IsDefined(user)) {
        return false;
    }
    return portfolio.profile_id === user.activeProfileId ||
        portfolio.profile_id === user.unactiveProfileId;
};
const isUserSubsbribedToPortfolio = (portfolio, user) => {
    if (!IsDefined(portfolio) || !IsDefined(user)) {
        return false;
    }
    return !!portfolio.user_subscription_summary;
};
export { isUserPortfolioOwner, isUserSubsbribedToPortfolio, };
